<template>
  <div id="privacy" class="privacy-policy col sm-col sm-col-12 md-col-6 lg-col-4 mb4">
    <div class="pl2 pr2">
      <h3>Privacy Policy for Phantom Rose Scarlet</h3>
      Last Updated: June 2, 2020<br /><br />

      Studio Maka LLC ("company", "we", or "Studio Maka") respects your privacy. The application or service ("Phantom
      Rose Scarlet") is provided by Studio Maka LLC at no cost and is intended for use as is. This article is used to
      inform visitors regarding the policies with the collection and use of personal information if any, in using the
      application. If you choose to use the application, then you agree to the collection and use of information in
      relation to this policy. The information that we collect is used for providing and improving the application.

      <h2>Information Collection and Use</h2>

      For improving the experience of the application, while using our application, we may require you to provide us
      with personal information to third party services that the application uses.

      <br />
      <br />
      The third party services may collect personal information used to identify you ("Apple Appstore", "Google
      Playstore"), serve targetted advertisments ("Google Admob"), and collect usage data analytics such as operating
      system version, device type, language, timezone, and other statistics ("Google Analytics", "Apple Appstore"). Link
      to privacy policy of these third party service providers used by the app are as follows:
      <br />
      <a href="http://www.apple.com/legal/privacy/">Apple Inc's Privacy Policy</a><br />
      <a href="https://policies.google.com/privacy">Google Inc's Privacy Policy</a>

      <h2>Changes to this privacy policy</h2>
      Studio Maka reserves the right to modify this privacy policy. Therefore, please review this page periodically.

      <h2>Contact Us</h2>
      If you have any questions about this privacy policy, please contact at: studiomakadev+feedback [at] gmail.com

      <div class="scarlet-border"></div>

      <h3>Privacy Policy for Phantom Rose Stickers for iMessage</h3>
      Last Updated: September 8, 2019<br /><br />

      Studio Maka LLC ("company", "we", or "Studio Maka") respects your privacy does not store or collect personal
      information from iMessage applications ("Phantom Rose Stickers") submitted and distributed on the Apple iOS App
      Store. Any non-personal anonymous data that may be collected is collected by Apple Inc and more information on
      Apple's data collection and privacy policy can be viewed at
      <a href="http://www.apple.com/legal/privacy/">http://www.apple.com/legal/privacy</a>. This privacy policy relates
      solely to sticker apps distrubed on Apple's iOS, iMessage App Store

      <h2>Changes to this privacy policy</h2>
      Studio Maka reserves the right to modify this privacy policy. Therefore, please review this page periodically.

      <h2>Contact Us</h2>
      If you have any questions about this privacy policy, please contact at: studiomakadev [at] gmail.com
    </div>
  </div>
</template>
<script>
export default {
  props: ['showPrivacy'],
  data: () => ({}),

  mounted() {
    //do something after mounting vue instance
    window.scrollTo(0, 0)
  },
}
</script>
<style lang="scss"></style>
