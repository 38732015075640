<template>
  <div class="privacy tall flex-auto flex justify-center">
    <PrivacyPolicy />
  </div>
</template>
<script>
import PrivacyPolicy from '@/components/PrivacyPolicy'

export default {
  name: '',
  data: () => ({
    source: '',
  }),

  components: {
    PrivacyPolicy,
  },
}
</script>
<style lang="scss"></style>
